.auth-page-ctr {
    background-color: var(--background);
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
    width: 80vw;
    height: 80vh;
    margin: 0 auto;
    transform: translateY(5vh);
    border-radius: 25px;
}

.auth-page-ctr > h1 {
    padding: 20px;
}

.login-signup-toggle-ctr {
    margin: 50px;
}

.auth-page-ctr p {
    padding: 10px;
    font-size: 17px;
}


.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
}