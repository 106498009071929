* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 

:root {
  --baby-blue: rgba(150, 194, 222, 1.0);
  --sky-blue: rgba(50, 152, 219, 1.0);

  /* --background: beige; */

  /* --background: rgb(245, 245, 221); */
  --background: rgb(173, 180, 191); 

  --h1-background: rgba(54, 81, 148, 1.0);

  --navy-blue: rgb(54, 81, 148);


  --dark-blue: rgb(50, 62, 90);

  /* --dark-blue: rgb(41, 76, 116); */

  /* adding below here */
  --shadow-color: rgb(28, 30, 34);

  --lightest-color: rgb(219, 217, 227);

  /* --wallpaper-color: rgba(245, 245, 245, 1.0); */
  /* slightly darker version of wallpaper color below */
  --wallpaper-color: rgb(231, 227, 227);

  --grey-blue: rgba(83, 92, 116, 1.0);

  /* --box-shadow: 0 0.3rem 0.4rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  prior version above, below version a bit more subtle */

  --box-shadow: 0 0.1rem 0.2rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: var(--baby-blue); */

  /* background-color: rgb(182, 208, 217); */
  background-color: var(--wallpaper-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  styling below for input fields */

input[type=text], input[type=Number], select {
  width: auto;
  /* padding: 8px 10px; */

  padding: 4px 5px;

  margin: 0px 20px 0px 0px;
  /* margin-right: 5px; */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--dark-blue);
  
  /* background-color: rgba(182, 208, 217, 0.3); */
  background-color: var(--wallpaper-color);
}

