footer {
    /* background-color: var(--background); */

    /* background-color: rgb(253, 231, 194); */

    background-color: var(--background);

    margin-top: auto;
    bottom: 0;
    width: 100vw;
    height: 3vh;
    gap: 15px;
    position: fixed;
    /* border-top: 3px solid rgb( 195, 169, 126); */
    border-top: 1px solid var(--shadow-color);
}

footer .social-media-icon {
    font-size: 16px;
    color: var(--dark-blue)
}
