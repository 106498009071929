.precipitants-table {
    border: 1px solid black;
    width: 90vw;
    margin: auto;
    background-color: var(--background);
}

.precipitants-table th {
    border: 1px solid rgb(189, 182, 182);
}

.precipitants-table td {
    border: 1px solid rgb(189, 182, 182);
}

/* .index-table th, td {
    border: 1px solid black;
    width: 40vw;
    background-color: var(--background);
} */

.baseline {
    /* border: 1px solid black; */
    width: 90vw;
    margin: auto;
    background-color: var(--background);
    border-radius: 25px;
   
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */

    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */

    /* box-shadow: var(--box-shadow); */

}

.baseline-table {
    /* border: 1px solid black; */
    width: 90vw;
    margin: auto;
    background-color: var(--background);
    /* border-radius: 25px; */
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
    color: var(--dark-blue)

}


.baseline-table th {
    border: 1px solid rgb(189, 182, 182);
    border-radius: 25px;
}

.baseline-table td {
    border: 1px solid rgb(189, 182, 182);
    border-radius: 25px;
}

.baseline-table th:last-child {
    border-right: none;
}

.baseline-table th:first-child {
    border-left: none;
}

.baseline-table td:last-child {
    /* border: 1px solid rgb(189, 182, 182); */
    border-bottom: none;
    border-right: none;
    border-bottom-left-radius: 25px;
    border-radius: 25px;
}


.baseline-table td:first-child {
    /* border: 1px solid rgb(189, 182, 182); */
    border-bottom: none;
    border-left: none;
    border-bottom-left-radius: 25px;
    border-radius: 25px;
}



.case-form-1 {
    background-color: var(--background);
    margin: 0 auto;
    margin-top: 5vh;
    padding: 25px;
    border-radius: 25px;
    width: 90vw;

    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */

    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}

.case-form-1 h2, label {
    padding: 10px;
}

.case-form-1 button {
    margin: 15px;
}

.case-form-page-2 {
    margin: 0 auto;
}

.case-form-page-2 h2 {
    padding: 18px;
}


.form-2 {
    background-color: var(--background);
    width: 90vw;
    margin: 25px auto;
    border-radius: 25px;

    /* padding: 25px; */
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}

.case-form-3 {
    background-color: var(--background);
    width: 90vw;
    margin: 0 auto;
    border-radius: 25px;
    margin-bottom: 60px;

    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}

.case-form-3 h2 {
    padding: 18px;
}

.case-form-3 button {
    margin: 15px;
}

#weight-tooltip-wrap {
    padding-right: 10px;
}

/* table tr:last-child, td:first-child {
    border-bottom-left-radius: 25px;
} */

.selector {
    margin-top: 0.2rem;
    font-size: 17px;
    /* width: 40vw; */

 
        width: auto;
        /* padding: 8px 10px; */
        padding: 4px 5px;
        /* margin: 5px 0px; */

        margin: 0px 20px 0px 20px;

        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        color: var(--dark-blue);
     
        background-color: var(--wallpaper-color)
}


.selector-form-3-text-area {
    font-size: 17px;
    width: 60vw;
    /* margin-right: 2rem; */
    /* width: auto; */
    /* padding: 8px 10px; */
    padding: 4px 5px;
    margin: 0px 20px 0px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    
    color: var(--dark-blue); 

    background-color: var(--wallpaper-color)
 
}

.selector-form-3 {
    font-size: 17px;
    width: 40vw;
    margin-right: 2rem;
}

.slider {
    margin-top: 0.5rem;
    font-size: 17px;
}

.case-form-1 .selector {
    width: 40vw;
}

@media only screen and (max-width: 700px) {
    .precipitants-form {
        flex-direction: column;
    }
}

.space-below-header {
    margin-bottom: 0.5rem;
}