.about-ctr {
    margin-bottom: 50px;
}

.about-section {
    background-color: var(--background);
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
    margin: 25px;
    border-radius: 25px;
}

.about-section-text {
    gap: 15px;
    padding: 25px;
    text-align: left;
    margin-right: auto;
}

.about-section-text-founding-devs {
    gap: 15px;
    padding: 20px;
}

.about-section span {
    font-style: italic;
    /* color: rgba(167, 133, 89, 1.0); */
}

.about-icon-div {
    /* padding: 50px; */
    padding: 30px;
    background-color: var(--wallpaper-color);
    border-radius: 50%;
    margin: 10px 0px 10px 15px;
    /* box-shadow: 1px 1px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}

.about-icon {
    font-size: 75px;
    /* color: black; */
    /* color: rgba(203, 186, 163, 1.0); */
    color: var(--grey-blue);
}

.contact-links {
    /* background-color: rgba(203, 186, 163, 1.0); */
    background-color: var(--grey-blue);
    padding: 5px;
    border-radius: 25px;
    flex-direction: row;
    /* box-shadow: 1px 1px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
}

.contact-links a {
    padding: 10px;
}

.our-team {
    padding: 25px;
    
}

.headshot {
    width: 175px;
    height: auto;
    border-radius: 50%;
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}


.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
}

.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}

.about-ctr .social-media-icon {
    /* color: black; */
    color: var(--dark-blue);
    font-size: 20px;
    transition: 0.25s;
    color: var(--wallpaper-color);
  }
  
  .about-ctr .social-media-icon:hover {
    font-size: 30px;
    /* color: rgba(54, 81, 148, 1.0); */
    transition: 0.25s;
    border-radius: 5px;;
  }

  /* .profile {
    width: 450px;
  } */

@media only screen and (max-width: 900px) {

    .about-icon-div
    {
        display: none;
    }
  }
  

@media only screen and (max-width: 700px) {
    .our-team {
        display: flex;
        flex-direction: column;
    }
    .headshot {
        width: 130px;
    }
    .about-section-text h2 {
        font-size: 20px;
    }
    .profile {
        width: 60vw;
    }
    .our-team {
        padding: 15px;
    }
  }


