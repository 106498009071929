
.index-table {
    width: 90vw;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

    border-radius: 25px;
}

.index-table th, .index-table td {
    border: 1px solid rgb(191, 186, 186);
    /* width: 40vw; */
    background-color: var(--background);
    padding: 10px;
    color: var(--dark-blue);
    /* border-right: none; */
    /* border-left: none; */
    /* border-top: none; */
    /* border-bottom: none; */
}

.index-table tr:last-child td:last-child {
    border-bottom-right-radius: 25px;
    border-right: none;
    border-bottom: none;
}

.index-table tr:last-child td:first-child {
    border-bottom-left-radius: 25px;
    border-left: none;
    border-bottom: none;
}

.index-table th:first-child {
    border-top-left-radius: 25px;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid rgb(160, 155, 155);
}

.index-table tr:first-child th {
    border-top: none;
    border-right: none;
    border-left: none;

}

.index-table th:last-child {
    border-top-right-radius: 25px;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid rgb(160, 155, 155);
}

.index-table th {
    border-bottom: 1px solid rgb(160, 155, 155);
}

.index-table tr:last-child {
    border-bottom: none;
}

.cases-index-ctr button {
    margin: 20px;
}

th.delete-cell, td.delete-cell {
    width: 100px;
}

td.delete-cell > button {
    padding: 5px;
    font-size: 12px;
    margin: 0;
}

.case-id-link {
    display: flex;
    justify-content: center;
    color: navy;
    font-size: 14px;
}

.new-case-div {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 90vw;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

    border-radius: 25px;
    background-color: var(--background);
    padding: 5px;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.new-case-div-text {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 90vw;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

    border-radius: 25px;
    background-color: var(--background);
    padding: 20px;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
}

@media only screen and (max-width: 700px) {
    .index-table th, td {
        padding: 2px;
    }
}