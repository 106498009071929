nav {
    background-color: var(--shadow-color);


    /* background-color: rgb(253, 231, 194); */
    
    height: 6vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    z-index: 10;
    box-shadow: 0px 3px 1px rgb(28, 30, 34);
}

.nav-link {
    /* padding: 10px; */

    padding: 7px;


    background-color: rgb(28, 30, 34);
    box-shadow: none;

    /* background-color: rgb(66, 105, 129); */


    /* color: rgb(241, 234, 210) */

    color: rgb(245, 245, 241);
    transition: 0.3s
}

.nav-link:hover {
    font-size: 0.9em;
}

.link {
    margin: 0 10px;
    /* padding: 10px; */
    padding: 5px;
}

.logout {
    margin-left: auto;
}

.profile-button h3, h4, p {
    display: inline;
}

.profile-button p {
    display: inline;
    padding-left: 5px;
}
