.App {
  text-align: center;

  /* background-color: var(--baby-blue); */

  background-color: var(--wallpaper-color);
  /* consideration of other color for background */

  color: var(--dark-blue);
  font-family: 'Roboto', sans-serif;

}

.open-ai-text {
  color: rgb(87, 93, 89);
  font-style: italic;
  white-space: pre-wrap;
}

.flex-ctr-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-ctr-ctr-space-even {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 90vw; */
  align-items: center;
}

.flex-ctr-ctr-space-left {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
  gap: 3%;
  /* width: 90vw; */
  align-items: center;
}

.flex-ctr-ctr-space-left-indent {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  gap: 3%;
  /* width: 90vw; */
  align-items: center;
}

.flex-ctr-ctr-space-left-indent-open-ai {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  gap: 3%;
  /* width: 90vw; */
  align-items: center;
  color: rgb(87, 93, 89);
}

.flex-ctr-ctr-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

button {
  padding: 10px;
  border-radius: 15px;
  /* background-color: var(--navy-blue); */
  background-color: var(--dark-blue);
  color: white;
  /* new below */
  border: none;
  box-shadow: var(--box-shadow);
}

button:hover {
  background-color: var(--shadow-color);
  cursor: pointer;
}

button:disabled {
  background-color: grey;
}

table, th, td {
  border-collapse: collapse;
  padding: 5px; 
  text-align: center;
}

.tooltip-wrap {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  z-index: 5;
  bottom: 5%;
  left: 5%;
  right: 5%;
  background-color: #fff;
  padding: .5em;
  min-width: 10rem;
  border-radius: 25px;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
}
.tooltip-content > p {
  font-size: 12px;
  color: black;
}
.tool-tip-icon {
  color: red;
}

.banner {
  /* background-color: var(--h1-background); */

  /* background-color: rgb(41, 76, 116); */

  background-color: rgb(55, 78, 105);

  padding: 25px;
  color: var(--wallpaper-color);
  gap: 10px;
}

.flex-start-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  /* flex-wrap: wrap */
}

