
.edit-user-form {
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    background: var(--background);
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

    padding: 30px;
    border-radius: 25px;
}

.edit-user-form > label {
    font-size: 18px;

}

.edit-user-form > input {
    height: 30px;

    width: auto;
    padding: 8px 10px;
    margin: 5px 0px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--dark-blue);

    background-color: var(--wallpaper-color)
}

.edit-user-ctr h6 > span {
    color: var(--shadow-color);
    font-style: italic;
}