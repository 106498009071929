
.user-information-ctr {
    background-color: var(--background);
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
    padding: 10px;
    /* border: 1px solid black; */
    gap: 20px;
    border-radius: 25px;
}

.user-information-ctr button {
    margin: 20px;
}
