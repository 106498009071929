.baseline {
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.space-below-header {
    margin-bottom: 0.5rem;
}

.patient-info-table {
    width: 90vw;
    margin-top: 3vh;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);
    margin-bottom: 3vh;
}

.patient-info-table th, .patient-info-table td {
    border: 1px solid rgb(189, 182, 182);
    background-color: var(--background);
    margin: auto;
}

.patient-info-table th:first-child {
    border-top-left-radius: 25px;
}

.patient-info-table th:last-child {
    border-top-right-radius: 25px;
}

.baseline-table tr:last-child td {
    border-bottom: none;
}

.baseline-table {
    width: 90vw;
}

@media only screen and (max-width: 650px) {
    .baseline-table {
        display: none;
    }
  }

.bottom-of-info-table {
    height: auto;
}

.bottom-of-info-table td {
    border: none;
}

.bottom-of-info-table td:last-child {
    border-bottom-right-radius: 25px;
}

.bottom-of-info-table td:first-child {
    border-bottom-left-radius: 25px;
}

.case-show-ctr button {
    margin: 25px;
}

.med-hx-ctr {
    background-color: var(--background);
    width: 90vw;
    margin: 0 auto;
    border: 1px solid black;
    padding: 25px;
    margin-bottom: 6vh;
}

.med-hx-note-style-ctr {
    background-color: var(--background);
    width: 90vw;
    max-width: 1000px;
    margin: 0 auto;
    padding: 25px;
    margin-bottom: 6vh;
    border-radius: 25px;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    /* box-shadow: 2px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--box-shadow);

}

.med-hx-note-style-text {
    text-align: left;
    margin-right: auto;
}

.med-hx-ctr section {
    margin: 25px;
}

.med-hx-ctr h2 {
    padding: 10px;
}

.med-hx-ctr h3 {
    padding: 10px;
}

.med-hx-ctr p, h4 {
    text-align: left;
}


.impression-ctr {
    text-align: left;
}


li {
    margin-right: auto;
    text-align: left;
}

.open-ai-div {
    margin-top: 10px;
    /* width: 90vw; */
    padding: 10px;
}

.delete {
    background-color: rgb(155, 113, 123);
}

.delete:hover {
    background-color: rgb(222, 144, 144);
}

.baseline-mobile {
    display: none;
}

.baseline-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 25px;
    /* border: 1px solid red; */
}

@media only screen and (max-width: 750px) {
    .baseline-desktop {
        display: none;
    }
    .baseline-mobile {
        display: block;
    }
    .baseline-grid {
        gap: 12px;
        padding: 10px;
    }
}